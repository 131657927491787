import { create } from "zustand";
import { persist } from "zustand/middleware";

const useUserStore = create(
  persist(
    (set) => ({
      user: {
        id: "",
        name: "",
        email: "",
        language: "",
        loginToken: null,
        isAdmin: false,
      },
      setLanguage: (language) =>
        set((state) => ({
          user: {
            ...state.user,
            language,
          },
        })),
      setUser: (id, name, email, language, loginToken) =>
        set((state) => ({
          user: {
            ...state.user,
            id,
            name,
            email,
            language,
            loginToken,
          },
        })),
      setAdminStatus: (isAdmin) =>
        set((state) => ({
          user: {
            ...state.user,
            isAdmin,
          },
        })),
    }),
    {
      name: "user-store", // unieke naam voor de store in de lokale opslag
      getStorage: () => localStorage, // specificeer localStorage als het opslagtype
    }
  )
);

export default useUserStore;
