import { create } from "zustand";
import { persist } from "zustand/middleware";

const useQuestionnaireStore = create(
  persist(
    (set) => ({
      availableQuestionnaires: [],
      questionnaireResults: [],

      // Function to set available questionnaires
      setAvailableQuestionnaires: (questionnaires) =>
        set(() => ({
          availableQuestionnaires: questionnaires,
        })),

      // Function to set questionnaire results
      setQuestionnaireResults: (results) =>
        set(() => ({
          questionnaireResults: results,
        })),

      // Function to add multiple questionnaire results, avoiding duplicates
      addQuestionnaireResults: (newResults) => {
        console.log(
          "addQuestionnaireResults: add new Results =" +
            JSON.stringify(newResults),
        );
        set((state) => ({
          questionnaireResults: [
            ...state.questionnaireResults,
            ...newResults.filter(
              (nr) =>
                !state.questionnaireResults.some(
                  (r) =>
                    r.id === nr.id && r.questionnaireId === nr.questionnaireId,
                ),
            ),
          ],
        }));
      },

      // Optional: Function to update a specific questionnaire result
      updateQuestionnaireResult: (updatedResult) =>
        set((state) => ({
          questionnaireResults: state.questionnaireResults.map((result) =>
            result.id === updatedResult.id ? updatedResult : result,
          ),
        })),

      // Other functions can be added as needed
    }),
    {
      name: "questionnaire-store", // unique name for the store in the local storage
      getStorage: () => localStorage, // specify localStorage as the storage type
    },
  ),
);

export default useQuestionnaireStore;

// QuestionnaireResults looks like this
////////////////////////////////////////
// [
//   {
//     "id": 1,
//     "questionnaireId": 1,
//     "userId": 1,
//     "status": "IN_PROGRESS",
//     "started": "2023-12-07T22:52:37.529931Z",
//     "lastUpdated": "2023-12-07T22:52:37.529931Z"
//   },
//   {
//     "id": 2,
//     "questionnaireId": 1,
//     "userId": 1,
//     "status": "IN_PROGRESS",
//     "started": "2023-12-07T22:52:37.548842Z",
//     "lastUpdated": "2023-12-07T22:52:37.548842Z"
//   }
// ]

// Available questionnaires looks like this:
// [
//   {
//     "id": 1,
//     "locale": "en_US.UTF-8",
//     "questionIds": [
//       1,
//       2,
//       3
//     ]
//   },
//   {
//     "id": 2,
//     "locale": "en_US.UTF-8",
//     "questionIds": [
//       1,
//       2,
//       3
//     ]
//   }
// ]
