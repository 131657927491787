import { Accordion } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import useUserStore from "../../stores/userStore";
import axios from "axios";
import Cookies from "js-cookie";
import TrafficLightGreen from "../../img/traffic-green.svg";
import TrafficLightOrange from "../../img/traffic-orange.svg";
import TrafficLightRed from "../../img/traffic-red.svg";
import { useTranslation } from "react-i18next";

const Indicators = ({ areaId, resultId }) => {
  const user = useUserStore((state) => state.user);
  const [indicators, setIndicators] = useState([]); // Answers given earlier and loaded from the API
  const [allAreas, setAllAreas] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(user.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Aanroepen binnen useEffect of een ander deel van de component na het laden van de indicatoren
  // Zorg ervoor dat je de gegroepeerde en gesorteerde indicatoren set in de component state

  useEffect(() => {
    // Stel dat setIndicators de gegroepeerde en gesorteerde indicatoren set
    // na het ophalen van de gegevens van de API
    let headersList = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.loginToken,
      "Accept-Language": user.language,
    };
    axios
      .get(
        process.env.REACT_APP_MTBS_API_URL +
          "indicators/results/" +
          resultId +
          "/areas/" +
          areaId,
        {
          headers: headersList,
        },
      )
      .then((response) => {
        //   const groupedAndSortedIndicators = groupAndSortIndicators(response.data);
        setIndicators(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // console.log('Indicators: load Error 401: Token invalid='+JSON.stringify(user.loginToken));
          Cookies.remove("userData");
        } else {
          // Handle other errors
          console.log(
            "Survey: load questionnaires areas, Error= " +
              error.response.status +
              "; " +
              error.message,
          );
        }
      });

    // load the areas for the Tier names
    if (user) {
      let headersList = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.loginToken,
        "Accept-Language": user.language,
      };
      console.log("Survey: starting to load data");
      // get the area and it's tiers
      axios
        .get(process.env.REACT_APP_MTBS_API_URL + "questionnaires/areas", {
          headers: headersList,
        })
        .then((response) => {
          console.log(response.data);
          setAllAreas(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log(
              "Survey: load questionnaires areas Error 401: Token invalid=" +
                JSON.stringify(user.loginToken),
            );
            Cookies.remove("userData");
          } else {
            // Handle other errors
            console.log(
              "Survey: load questionnaires areas, Error= " +
                error.response.status +
                "; " +
                error.message,
            );
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId, user]);

  const groupAndSortIndicators = (indicators) => {
    // Groepeer eerst op tier
    const groupedByTier = indicators.reduce((acc, rec) => {
      // Vind de tierName op basis van rec.tierId
      const tierName = allAreas.reduce((name, area) => {
        const tier = area.tier.find((t) => t.id === rec.tierId);
        return tier ? tier.tierName : name;
      }, "");

      const tierKey = tierName
        ? `${t("Tier")} ${rec.tierId}: ${tierName}`
        : `${t("Tier")} ${rec.tierId}`; // Gebruik tierName als beschikbaar
      if (!acc[tierKey]) {
        acc[tierKey] = [];
      }
      acc[tierKey].push(rec);

      return acc;
    }, {});

    // Sorteer elke groep op impact en dan op timeline
    Object.keys(groupedByTier).forEach((tier) => {
      groupedByTier[tier].sort((a, b) => {
        if (a.impact === b.impact) {
          return a.timeline - b.timeline;
        }
        return b.impact - a.impact;
      });
    });

    return groupedByTier;
  };

  const renderGroupedAndSortedIndicators = () => {
    const grouped = groupAndSortIndicators(indicators);
    return Object.entries(grouped).map(([tier, recs], index) => {
      return (
        <div key={index} className="mb-5">
          <h3 className="mt-3">{tier}</h3>
          <Accordion>
            {recs.map((indicator, recIndex) => {
              let TrafficLightSvg;
              switch (indicator.status) {
                case "GREEN":
                  TrafficLightSvg = (
                    <img
                      src={TrafficLightGreen}
                      alt="Green Light"
                      style={{ width: "90px" }}
                    />
                  );
                  break;
                case "YELLOW":
                  TrafficLightSvg = (
                    <img
                      src={TrafficLightOrange}
                      alt="Orange Light"
                      style={{ width: "90px" }}
                    />
                  );
                  break;
                case "RED":
                  TrafficLightSvg = (
                    <img
                      src={TrafficLightRed}
                      alt="Red Light"
                      style={{ width: "90px" }}
                    />
                  );
                  break;
                default:
                  TrafficLightSvg = null;
              }

              return (
                <Accordion.Item eventKey={String(recIndex)} key={recIndex}>
                  <Accordion.Header>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <span>{indicator.title}</span>
                      {TrafficLightSvg}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="indicator-description-subtitle">
                      {t("Description")}
                    </div>
                    <div className="indicator-description">
                      {indicator.description}
                    </div>
                    <div className="indicator-reason-subtitle">
                      {t("Reason")}
                    </div>
                    <div className="indicator-status-description">
                      {indicator.statusDescription}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      );
    });
  };

  // Renderfunctie aanpassen om gegroepeerde indicatoren te tonen
  return (
    <>
      <h2 class="results">{t("Indicators")}</h2>
      {renderGroupedAndSortedIndicators()}
    </>
  );
  // return <>
  // <h2>Indicators</h2>
  // {/* {renderGroupedAndSortedRecommendations()} */}
  // </>;
};

export default Indicators;
