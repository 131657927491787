import React, { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import Cookies from "js-cookie";
import * as Yup from "yup";
import { useFormik } from "formik";
import LayoutFullpage from "../../layout/LayoutFullpage";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useUserStore from "../../stores/userStore";
import { jwtDecode } from "jwt-decode";
import useQuestionnaireStore from "../../stores/questionnaireStore";
import HeaderLogo from "../../assets/svg/brand/logo-for-header.png";
import i18n from "i18next";
import Popup from "reactjs-popup";

const Login = () => {
  const navigate = useNavigate();
  const setUser = useUserStore((state) => state.setUser);
  const setAdminStatus = useUserStore((state) => state.setAdminStatus);
  const user = useUserStore((state) => state.user);
  const [finishedLogginIn, setFinishedLogginIn] = useState(false);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const availableQuestionnaires = useQuestionnaireStore(
    (state) => state.availableQuestionnaires
  );
  const setAvailableQuestionnaires = useQuestionnaireStore(
    (state) => state.setAvailableQuestionnaires
  );
  // const addQuestionnaireResults = useQuestionnaireStore(state => state.addQuestionnaireResults);
  const setQuestionnaireResults = useQuestionnaireStore(
    (state) => state.setQuestionnaireResults
  );

  const [loginError, setLoginError] = useState("");

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Login name is required"),
    password: Yup.string()
      .min(6, "Must be at least 6 chars!")
      .required("Password is required"),
  });
  const initialValues = { email: "", password: "" };

  const onSubmit = (values) => {
    // console.log('submitting login form, user in store =' + JSON.stringify(user))
    setFinishedLogginIn(false);
    Cookies.remove("userData");
    setUser("", "", "", "", null);

    axios
      .post(process.env.REACT_APP_MTBS_API_URL + "users/login", {
        email: values.email.trim().toLowerCase(),
        password: values.password.trim(),
      })
      .then((response) => {
        const { jwt } = response.data;
        const decoded = jwtDecode(jwt);
        const userId = decoded.sub; // Extract 'sub' field from decoded JWT token
        const isAdmin = decoded.groups.includes("admin");
        const user = {
          id: userId, // Added the userId from JWT token
          name: response.data.userName, // Update this with actual user name if available
          email: values.email.toLowerCase(),
          language: response.data.preferredLocale
            .substring(0, 5)
            .replace("_", "-"),
          loginToken: jwt,
        };

        i18n.changeLanguage(user.language);

        Cookies.set("userData", JSON.stringify(user), {
          expires: 7,
          secure: true,
          sameSite: "none",
        });
        // console.log('login success: ' +JSON.stringify(user))
        setUser(
          user.id,
          user.name,
          user.email.toLowerCase(),
          user.language,
          user.loginToken
        );
        setAdminStatus(isAdmin);
        setFinishedLogginIn(true);
      })
      .catch((error) => {
        console.error("Login error", JSON.stringify(error));
        setLoginError(error.message);
      });
  };

  const loadAvailableQuestionnaires = useCallback(() => {
    if (user && user.loginToken !== "" && user.loginToken) {
      let headersList = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.loginToken,
        "Accept-Language": user.language,
      };
      // console.log("headers=" + JSON.stringify(user.language));
      axios
        .get(process.env.REACT_APP_MTBS_API_URL + "questionnaires/", {
          headers: headersList,
        })
        .then((response) => {
          console.log(
            "Login: loadAvailableQuestionnaires: " +
              JSON.stringify(response.data)
          );
          const highestIdQuestionnaire = response.data.sort(
            (a, b) => b.id - a.id
          )[0];
          setAvailableQuestionnaires([highestIdQuestionnaire]);

          // setAvailableQuestionnaires(response.data);
          // loop through all questionnaires and load their results, if any
          // setAreas(response.data)
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            // console.log('Login loadAvailableQuestionnaires: error 401, Token invalid ='+JSON.stringify(user.loginToken));
            Cookies.remove("userData");
            setUser("", "", "", "", null);
          } else {
            // Handle other errors
            // console.log('Login loadAvailableQuestionnaires: Error '+JSON.stringify(error.response.status)+', '+ JSON.stringify(error.message));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const createNewQuestionnaireResult = useCallback((questionnaireId) => {
    // console.log('create new questionnaire result with token= '+user.loginToken);

    let headersList = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.loginToken,
      "Accept-Language": user.language,
    };
    axios
      .post(
        process.env.REACT_APP_MTBS_API_URL +
          "results/users/" +
          user.id +
          "/questionnaires/" +
          questionnaireId +
          "/create",
        {},
        { headers: headersList }
      )
      .then((response) => {
        // console.log('Login: new questionnaire created')
        // console.log(JSON.stringify(response.data))
        //Reload the questionnairs so we can add it to the state store
        // loadQuestionnairesResults(questionnaireId);
      })
      .catch((error) => {
        // Handle error here
        console.error(
          "Login: Error creating Questionnaire Result",
          JSON.stringify(error)
        );
        // if(error.status === 403):
        setLoginError(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadQuestionnairesResults = useCallback(
    (questionnaireId) => {
      let headersList = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.loginToken,
        "Accept-Language": user.language,
      };

      axios
        .get(
          process.env.REACT_APP_MTBS_API_URL +
            "results/users/" +
            user.id +
            "/questionnaires/" +
            questionnaireId,
          {
            headers: headersList,
          }
        )
        .then((response) => {
          setQuestionnaireResults(response.data);
          const hasIncompleteQuestionnaires = response.data.some(
            (q) => q.status === "IN_PROGRESS"
          );
          if (response.data.length === 0 || !hasIncompleteQuestionnaires) {
            // Alle questionnaires zijn "COMPLETED" of er zijn geen questionnaires, maak een nieuwe aan
            createNewQuestionnaireResult();
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log(
              "loadQuestionnairesResults ERROR 401, Token rejected: " +
                JSON.stringify(user.loginToken)
            );
            Cookies.remove("userData");
            setUser("", "", "", "", null);
          } else {
            console.log("Other error: ", error.message);
          }
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  ); // Only re-create this function when `user` changes

  useEffect(() => {
    // Check if availableQuestionnaires is loaded and has items
    if (
      user &&
      user.id !== "" &&
      user.loginToken &&
      finishedLogginIn &&
      availableQuestionnaires &&
      availableQuestionnaires.length > 0
    ) {
      console.log(
        "Login: Looking for results for " +
          availableQuestionnaires.length +
          " questionnaires.."
      );
      // Load results for each questionnaire
      availableQuestionnaires.forEach((questionnaire) => {
        console.log(
          "Login: calling loadQuestionnairesResults() with questionnaireId=" +
            questionnaire.id
        );
        loadQuestionnairesResults(questionnaire.id);
      });
      setFinishedLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, finishedLogginIn, availableQuestionnaires]);

  useEffect(() => {
    // Logout user and clear all data when page loads for TESTING: Maybe remove later..
    // console.log("LOGIN: PAGE start");
    // console.log("finishedloading=" + JSON.stringify(finishedLoading));
    setUser("", "", "", "", null);
    setQuestionnaireResults([]);
    setAvailableQuestionnaires([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (finishedLogginIn && user) {
      loadAvailableQuestionnaires();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedLogginIn, user]);

  useEffect(() => {
    // navigate to landing page when everything is loaded
    if (user && finishedLoading) {
      // console.log("user && finishedloading");
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedLoading]);

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  const leftSide = (
    // <div className="min-h-100 d-flex align-items-center imagebox">
    <div className='min-h-100 d-flex align-items-center'>
      <div className='w-100 w-lg-75 w-xxl-50'>
        <div>
          <div className='mb-5'>
            <h1 className='display-3 text-white'>PMAWCA Sustainable Ports</h1>
            {/* <h1 className="display-3 text-white">Let's see how your seaport cang</h1> */}
          </div>
          <p className='h6 text-white lh-1-5 mb-5'>Self-Assessment Tool</p>
        </div>
      </div>
    </div>
  );

  const rightSide = (
    <div className='sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border'>
      <div className='sw-lg-50 px-5'>
        <div className='sh-11'>
          <NavLink to='/'>
            <img
              className='logo-image-login mb-1 mt-1'
              src={HeaderLogo}
              alt='Logo'
            />
          </NavLink>
        </div>
        <div className='mb-5'>
          {/* <p>{JSON.stringify(user)}</p>
        <p>{JSON.stringify(finishedLogginIn)}</p> */}
          <h2 className='cta-1 mb-0 text-primary'>Welcome,</h2>
          <h2 className='cta-1 text-primary'>let's get started!</h2>
        </div>
        <div className='mb-5'>
          <p className='h6'>Please use your credentials to login.</p>
        </div>
        <div>
          <form
            id='loginForm'
            className='tooltip-end-bottom'
            onSubmit={handleSubmit}
          >
            <div className='mb-3 filled form-group tooltip-end-top'>
              {/* <CsLineIcons icon="email" /> */}
              <Form.Control
                type='text'
                name='email'
                placeholder='Email'
                value={values.email}
                onChange={handleChange}
              />
              {errors.email && touched.email && (
                <div className='d-block invalid-tooltip'>{errors.email}</div>
              )}
            </div>
            <div className='mb-3 filled form-group tooltip-end-top'>
              {/* <CsLineIcons icon="lock-off" /> */}
              <Form.Control
                type='password'
                name='password'
                onChange={handleChange}
                value={values.password}
                placeholder='Password'
              />
              {errors.password && touched.password && (
                <div className='d-block invalid-tooltip'>{errors.password}</div>
              )}
            </div>
            <Button size='lg' type='submit'>
              Login
            </Button>
            <Popup trigger={<Button size='lg'> Forgot?</Button>}>
              <div className='forgot-popup'>
                Please contact the PMAWCA secretariat to recover your
                credentials.
              </div>
            </Popup>
            <p>{loginError}</p>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {/* <HtmlHead title={title} description={description} /> */}
      <LayoutFullpage left={leftSide} right={rightSide} />
    </>
  );
};

export default Login;
