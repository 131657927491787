import React, { useState, useEffect } from "react";
import { Dropdown, Row, Col } from "react-bootstrap";
import tier0GeneralImage from "../../img/Tier1-Priorities.png";
import tier1PrioritiesImage from "../../img/Tier1-Priorities.png";
import tier2PoliciesImage from "../../img/Tier2-Policies.png";
import tier3SituationImage from "../../img/Tier3-Situation.svg";
import tier4ToolsImage from "../../img/Tier4-Tools.svg";
import tier5InfrastructureImage from "../../img/Tier5-Infrastructure.png";
// import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { useTranslation } from "react-i18next";
import useUserStore from "../../stores/userStore";

const TierCard = ({ tier }) => {
  const { t, i18n } = useTranslation();
  const user = useUserStore((state) => state.user);

  useEffect(() => {
    i18n.changeLanguage(user.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tiersImages = {
    general: tier0GeneralImage,
    priorities: tier1PrioritiesImage,
    policies: tier2PoliciesImage,
    situation: tier3SituationImage,
    tools_and_techniques: tier4ToolsImage,
    infrastructure: tier5InfrastructureImage,
  };

  const tierName = tier.tierName.replace(/\s/g, "_").toLowerCase();
  return (
    <>
      <Row>
        <Col className="tiers-status-column" lg="2"></Col>

        <Col lg="5">
          <p className="text-small-upper">{t("Tier")}</p>
        </Col>
        {/* <Col lg="3">
              <p className='text-small-upper'>Status</p>
            </Col> */}
      </Row>
      <Row>
        <Col className="tiers-status-column" lg="2">
          <img
            className="tiers-image-small"
            src={tiersImages[tierName]}
            alt={tier.tierName}
          />
        </Col>

        <Col lg="5">
          <p className="tiers-status-text capitalized">{t(tier.tierName)}</p>
        </Col>
        {/* <Col lg="3">
              <p className='tiers-status-text uppercase'>{tier.finished ? 'finished' : 'open'}</p> 
            </Col> */}
      </Row>
    </>
  );
};

const TierStatusses = ({ tiers, activeTierId, jumpToTierId }) => {
  const [activeTier, setActiveTier] = useState(null);
  // Initialise / load the data
  useEffect(() => {
    console.log("activeTierId=" + JSON.stringify(activeTierId));
    if (activeTierId && tiers) {
      setActiveTier(tiers.find((a) => a.id === parseInt(activeTierId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTierId]);

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle className="tier-select-dropdown">
          {activeTier ? (
            <>
              <TierCard tier={activeTier} />
            </>
          ) : (
            <></>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {/* Do not list the active Tier in the dropdown  */}
          {tiers ? (
            tiers.map((tier) => {
              // Als de tier ID gelijk is aan activeTierId, sla deze tier over
              if (tier.id === activeTierId) {
                return null;
              }

              // Render de tier als de ID niet overeenkomt met activeTierId
              return (
                <Dropdown.Item
                  key={tier.id}
                  className="tiers-status-card-horizontal"
                  onClick={() => jumpToTierId(tier.id)}
                >
                  <TierCard tier={tier} />
                </Dropdown.Item>
              );
            })
          ) : (
            <></>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default TierStatusses;
