import React, { useState, useEffect } from "react";
import { Table, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { Accordion } from "react-bootstrap";
import useUserStore from "../../stores/userStore";
import coastalErosion_R1_05 from "../../img/MTBS-Area-CoastalErosion-R1-05.png";
import coastalErosion_R2_01_01 from "../../img/MTBS-Area-CoastalErosion-R2-01-01.png";
import coastalErosion_R2_01_02 from "../../img/MTBS-Area-CoastalErosion-R2-01-02.png";
import coastalErosion_R2_01_03 from "../../img/MTBS-Area-CoastalErosion-R2-01-03.png";
import coastalErosion_R4_06 from "../../img/MTBS-Area-CoastalErosion-R4-06.png";
import energyEfficiency_R3_01 from "../../img/MTBS-Area-EnergyEfficiency-R3-01.png";
import wasteManagement_R3_03 from "../../img/MTBS-Area-WasteManagement-R3-03.png";
import wasteManagement_R3_05_01 from "../../img/MTBS-Area-WasteManagement-R3-05-1.png";
import wasteManagement_R3_05_02 from "../../img/MTBS-Area-WasteManagement-R3-05-2.png";
import wasteManagement_R3_07 from "../../img/MTBS-Area-WasteManagement-R3-07.png";
import wasteManagement_R4_01 from "../../img/MTBS-Area-WasteManagement-R4-01.png";
import wasteManagement_R4_02 from "../../img/MTBS-Area-WasteManagement-R4-02.png";
import wasteManagement_R4_03 from "../../img/MTBS-Area-WasteManagement-R4-03.png";
import wasteManagement_R4_09_01 from "../../img/MTBS-Area-WasteManagement-R4-09-1.png";
import wasteManagement_R4_09_02 from "../../img/MTBS-Area-WasteManagement-R4-09-2.png";
import wasteManagement_R4_10_01 from "../../img/MTBS-Area-WasteManagement-R4-10-1.png";
import wasteManagement_R4_10_02 from "../../img/MTBS-Area-WasteManagement-R4-10-2.png";
import wasteManagement_R4_11_01 from "../../img/MTBS-Area-WasteManagement-R4-11-1.png";
import wasteManagement_R4_12_01 from "../../img/MTBS-Area-WasteManagement-R4-12-1.png";
import wasteManagement_R4_12_02 from "../../img/MTBS-Area-WasteManagement-R4-12-2.png";
import { useTranslation } from "react-i18next";

const Recommendations = ({ areaId, resultId }) => {
  const user = useUserStore((state) => state.user);
  const [recommendations, setRecommendations] = useState([]);
  const [selectedCell, setSelectedCell] = useState(null);
  const [showAll, setShowAll] = useState(true);
  const [allAreas, setAllAreas] = useState([]);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(user.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   setIsLoading(true);
  //   if (user && resultId !== 0 && areaId) {
  //     const headersList = {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + user.loginToken,
  //       "Accept-Language": user.language,
  //     };
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_MTBS_API_URL}recommendations/results/${resultId}/areas/${areaId}`,
  //         {
  //           headers: headersList,
  //         }
  //       )
  //       .then((response) => {
  //         setRecommendations(response.data.filter((rec) => rec.active));
  //       })
  //       .catch((error) => {
  //         console.log("Error loading recommendations:", error);
  //       });
  //   }

  //   if (user) {
  //     let headersList = {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + user.loginToken,
  //       "Accept-Language": user.language,
  //     };
  //     console.log("Survey: starting to load data");
  //     // get the area and it's tiers
  //     axios
  //       .get(process.env.REACT_APP_MTBS_API_URL + "questionnaires/areas", {
  //         headers: headersList,
  //       })
  //       .then((response) => {
  //         setAllAreas(response.data);
  //       })
  //       .catch((error) => {
  //         if (error.response && error.response.status === 401) {
  //           console.log(
  //             "Survey: load questionnaires areas Error 401: Token invalid=" +
  //               JSON.stringify(user.loginToken)
  //           );
  //         } else {
  //           // Handle other errors
  //           console.log(
  //             "Survey: load questionnaires areas, Error= " +
  //               error.response.status +
  //               "; " +
  //               error.message
  //           );
  //         }
  //       });
  //   }
  // }, [user, resultId, areaId]);

  useEffect(() => {
    i18n.changeLanguage(user.language);
  }, [user.language, i18n]);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      if (user && resultId && areaId) {
        try {
          const headersList = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.loginToken,
            "Accept-Language": user.language,
          };

          const recommendationsResponse = await axios.get(
            `${process.env.REACT_APP_MTBS_API_URL}recommendations/results/${resultId}/areas/${areaId}`,
            { headers: headersList }
          );
          console.log(
            "unfiltered recommendations=" + recommendationsResponse.data.length
          );
          setRecommendations(
            recommendationsResponse.data.filter((rec) => rec.active)
          );

          const areasResponse = await axios.get(
            `${process.env.REACT_APP_MTBS_API_URL}questionnaires/areas`,
            { headers: headersList }
          );
          setAllAreas(areasResponse.data);
        } catch (error) {
          console.error("Error loading data:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [user, resultId, areaId]);

  function replaceImagePlaceholders(description) {
    if (!description) {
      return "";
    }
    const replacements = {
      coastalErosion_R1_05: coastalErosion_R1_05,
      coastalErosion_R2_01_01: coastalErosion_R2_01_01,
      coastalErosion_R2_01_02: coastalErosion_R2_01_02,
      coastalErosion_R2_01_03: coastalErosion_R2_01_03,
      coastalErosion_R4_06: coastalErosion_R4_06,
      energyEfficiency_R3_01: energyEfficiency_R3_01,
      wasteManagement_R3_03: wasteManagement_R3_03,
      wasteManagement_R3_05_01: wasteManagement_R3_05_01,
      wasteManagement_R3_05_02: wasteManagement_R3_05_02,
      wasteManagement_R3_07: wasteManagement_R3_07,
      wasteManagement_R4_01: wasteManagement_R4_01,
      wasteManagement_R4_02: wasteManagement_R4_02,
      wasteManagement_R4_03: wasteManagement_R4_03,
      wasteManagement_R4_09_01: wasteManagement_R4_09_01,
      wasteManagement_R4_09_02: wasteManagement_R4_09_02,
      wasteManagement_R4_10_01: wasteManagement_R4_10_01,
      wasteManagement_R4_10_02: wasteManagement_R4_10_02,
      wasteManagement_R4_11_01: wasteManagement_R4_11_01,
      wasteManagement_R4_12_01: wasteManagement_R4_12_01,
      wasteManagement_R4_12_02: wasteManagement_R4_12_02,
      // Voeg meer vervangingen toe zoals nodig
    };

    let replacedDescription = description;
    Object.keys(replacements).forEach((key) => {
      const value = replacements[key];
      replacedDescription = replacedDescription.replace(
        new RegExp(key.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g"),
        value
      );
    });

    return replacedDescription;
  }
  // Definieer de labels en achtergrondkleuren
  const timeLabels = [t("Quick win"), t("Short term"), t("Medium term")];
  const impactLabels = [t("High impact"), t("Medium impact"), t("Low impact")];
  const backgroundColors = [
    ["#51d870f2", "#51d87099", "#51d87050"], // High impact
    ["#51d87099", "#FBE7A3", "#FDF3D0"], // Medium impact
    ["#51d87050", "#FDF3D0", "#F8E5D8"], // Low impact
  ];

  const handleCellClick = (impactIndex, timelineIndex) => {
    setSelectedCell({ impactIndex, timelineIndex });
    setShowAll(false); // Toon alleen de recommendations die overeenkomen met de geselecteerde cel
  };

  const handleShowAllClick = () => {
    setSelectedCell(null);
    setShowAll(true); // Toon alle recommendations
  };

  const renderCellContent = (impactIndex, timelineIndex) => {
    const cellRecommendations = recommendations.filter(
      (rec) =>
        rec.impact === 3 - impactIndex && rec.timeline === timelineIndex + 1
    );
    return cellRecommendations.length;
  };

  const groupAndSortRecommendations = (recommendations) => {
    const groupedByTier = recommendations.reduce((acc, rec) => {
      const tier = allAreas
        .flatMap((area) => area.tier)
        .find((t) => t.id === rec.tierId);
      console.log("recommendations =" + JSON.stringify(recommendations));

      const tierName = tier ? tier.tierName : "Unknown Tier";
      const tierKey = `Tier ${rec.tierId}: ${tierName}`;

      if (!acc[tierKey]) {
        acc[tierKey] = [];
      }
      acc[tierKey].push(rec);
      return acc;
    }, {});

    Object.keys(groupedByTier).forEach((tier) => {
      groupedByTier[tier].sort((a, b) => {
        if (a.impact === b.impact) {
          return a.timeline - b.timeline;
        }
        return b.impact - a.impact;
      });
    });

    return groupedByTier;
  };

  const renderCombinedTimeImpactPill = (impactIndex, timelineIndex) => {
    const pillClassNames = [
      ["pill pill-high-quick", "pill pill-high-short", "pill pill-high-medium"],
      [
        "pill pill-high-short",
        "pill pill-medium-short",
        "pill pill-medium-long",
      ],
      ["pill pill-high-medium", "pill pill-medium-long", "pill pill-low-long"],
    ];
    // console.log(impactIndex, timelineIndex);
    return pillClassNames[3 - impactIndex][timelineIndex - 1];
  };

  const renderGroupedAndSortedRecommendations = () => {
    let filteredRecommendations = recommendations;

    if (!showAll && selectedCell) {
      const { impactIndex, timelineIndex } = selectedCell;
      filteredRecommendations = recommendations.filter(
        (rec) =>
          rec.impact === 3 - impactIndex && rec.timeline === timelineIndex + 1
      );
    }

    const grouped = groupAndSortRecommendations(filteredRecommendations);
    return Object.entries(grouped).map(([tier, recs], index) => (
      <div key={index} className='mb-5'>
        <h3 className='mt-3'>{tier}</h3>
        <Accordion>
          {recs.map((recommendation, recIndex) => {
            let impactText, impactClass;
            switch (recommendation.impact) {
              case 1:
                impactText = "Low impact";
                impactClass = "pill-low";
                break;
              case 2:
                impactText = "Medium impact";
                impactClass = "pill-medium";
                break;
              case 3:
                impactText = "High impact";
                impactClass = "pill-high";
                break;
              default:
                impactText = "Unknown impact";
                impactClass = "pill-unknown";
            }
            let timeText, timeClass;
            switch (recommendation.timeline) {
              case 1:
                timeText = "Quick win";
                timeClass = "pill-high";
                break;
              case 2:
                timeText = "Short term";
                timeClass = "pill-medium";
                break;
              case 3:
                timeText = "Medium term";
                timeClass = "pill-low";
                break;
              default:
                timeText = "Unknown implementation time";
                timeClass = "pill-unknown";
            }

            return (
              <Accordion.Item eventKey={String(recIndex)} key={recIndex}>
                <Accordion.Header>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <span>{recommendation.title}</span>
                    <span
                      className={renderCombinedTimeImpactPill(
                        recommendation.impact,
                        recommendation.timeline
                      )}
                    ></span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className='implementation-text'>
                    <span className='implementation-text'>
                      {t("Implementation Time")}:{" "}
                      <span className={`pill ${timeClass}`}>{t(timeText)}</span>
                    </span>
                  </div>
                  <div className='implementation-text'>
                    <span className='implementation-text'>
                      {t("Impact")}:{" "}
                      <span className={`pill ${impactClass}`}>
                        {t(impactText)}
                      </span>
                    </span>
                  </div>
                  <div className='recommendation-body-subtitle'>
                    {t("Description")}
                  </div>
                  <div
                    className='rendered-html-text'
                    dangerouslySetInnerHTML={{
                      __html: replaceImagePlaceholders(
                        recommendation.description
                      ),
                    }}
                  />
                  <div className='recommendation-body-subtitle'>
                    {t("Benefits")}
                  </div>
                  <div
                    className='recommendation-benefits'
                    dangerouslySetInnerHTML={{
                      __html: replaceImagePlaceholders(recommendation.benefits),
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    ));
  };

  console.log("nr of recommendations=" + recommendations.length);

  if (isLoading) {
    return (
      <Spinner animation='border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    );
  }
  return (
    <>
      <h2 className='results'>{t("Recommendations")}</h2>
      <Table bordered className='recommendations-table'>
        <thead>
          <tr>
            <th></th>
            {timeLabels.map((label, index) => (
              <th key={index}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {impactLabels.map((label, impactIndex) => (
            <tr key={impactIndex}>
              <td>{label}</td>
              {timeLabels.map((_, timelineIndex) => (
                <td
                  key={timelineIndex}
                  onClick={() => handleCellClick(impactIndex, timelineIndex)}
                  style={{
                    backgroundColor:
                      backgroundColors[impactIndex][timelineIndex],
                    cursor: "pointer",
                  }}
                >
                  {renderCellContent(impactIndex, timelineIndex)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ padding: "1% 0% 3% 0%" }}>
        <Button onClick={handleShowAllClick} className='c-button--primary'>
          {t("Show All")}
        </Button>
      </div>
      {renderGroupedAndSortedRecommendations()}
    </>
  );
};

export default Recommendations;
