import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useUserStore from "../../stores/userStore";
import HeaderForMenu from "../HeaderForMenu";
import { Button, Row, Table } from "react-bootstrap";
import data from "./mtbs_data.json";
import axios from "axios";
import airQualityImage from "../../img/MTBS-Area-AirQuality.svg";
import coastalErosionImage from "../../img/MTBS-Area-CoastalErosion.svg";
import wasteManagementImage from "../../img/MTBS-Area-WasteManagement.svg";
import energyEfficiencyImage from "../../img/MTBS-Area-EnergyEfficiency.svg";
import caseStudiesImage from "../../img/MTBS-CaseStudies.svg";
import documentationImage from "../../img/MTBS-Documentation.svg";
import referencesImage from "../../img/MTBS-References.svg";
import toolsImage from "../../img/MTBS-Tools2.svg";

function DataViewer() {
  const user = useUserStore((state) => state.user);
  const { subject } = useParams();
  const [filteredData, setFilteredData] = useState([]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [allAreas, setAllAreas] = useState([]);

  // Object met geïmporteerde afbeeldingen
  const areasImages = {
    1: wasteManagementImage,
    2: coastalErosionImage,
    3: energyEfficiencyImage,
    4: airQualityImage,
  };

  const subjectImages = {
    case_studies: caseStudiesImage,
    documentation: documentationImage,
    references: referencesImage,
    tools: toolsImage,
  };

  useEffect(() => {
    i18n.changeLanguage(user.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const toolsData = data.filter((item) => item.subject === subject);
    setFilteredData(toolsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      let headersList = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.loginToken,
        "Accept-Language": user.language,
      };
      console.log("Survey: starting to load data");
      axios
        .get(process.env.REACT_APP_MTBS_API_URL + "questionnaires/areas", {
          headers: headersList,
        })
        .then((response) => {
          setAllAreas(response.data);
        })
        .catch((error) => {
          // Error handling hier
        });
    }
  }, [user]);

  const backToHome = () => {
    navigate("/");
  };

  const formatSubjectText = (text) => {
    // Vervang underscores door spaties en maak de eerste letter een hoofdletter
    const result = text
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase());
    return result;
  };

  return (
    <>
      <HeaderForMenu></HeaderForMenu>
      <div className='wrapper'>
        <aside className='aside question-title'>
          <div className='aside-title'>
            <h1>{subject ? t(formatSubjectText(subject)) : null}</h1>
            {/* <h1>{t(subject)}</h1> */}
          </div>
          {subject ? (
            <div className='circle-image-wrapper-wrapper'>
              <div className='circle-wrapper'>
                <img
                  src={subjectImages[subject]}
                  className='landing-page-image'
                  alt={subject}
                />
              </div>
            </div>
          ) : null}
        </aside>
        <article className='aside-choices'>
          {allAreas.map((area) => (
            <div key={area.id}>
              <div className='table-title'>
                <img
                  src={areasImages[area.id]}
                  alt={area.areaName}
                  className='area-image table-image'
                />
                <p className='table-title-p'>
                  <h1 className='table-title-h1'>{t(area.areaName)}</h1>
                </p>
              </div>
              <Row>
                <Table
                  bordered
                  className='documentation-table documentation-table-gap'
                >
                  <tbody>
                    {filteredData
                      .filter((item) => item.Area === area.id)
                      .map((item) => (
                        <tr key={item.number} className='table-text'>
                          <td>{item.number}</td>
                          <td style={{ lineHeight: "175%" }}>
                            <a
                              href={item.link}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='link-underlined'
                            >
                              {item.title}
                            </a>
                          </td>
                          <td style={{ lineHeight: "175%" }}>{item.author}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Row>
            </div>
          ))}
          <div className='wizard__footer'>
            <Button
              className='c-button--primary button--icon'
              type='button'
              onClick={() => backToHome()}
            >
              <span className='button__label'>{t("Home")}</span>
            </Button>
          </div>
        </article>
      </div>
    </>
  );
}

export default DataViewer;
