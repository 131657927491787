import React, { useState, useEffect } from "react";
import HeaderForMenu from "../HeaderForMenu";
import { Button } from "react-bootstrap";
import useUserStore from "../../stores/userStore";
import axios from "axios";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { Parser } from "@json2csv/plainjs";

const Report = () => {
  const { t, i18n } = useTranslation();
  const [indicators, setIndicators] = useState([]);
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);
  const [transformedData, setTransformedData] = useState([]);
  const [allAreas, setAllAreas] = useState([]);

  // Function to transform the data into the required format, including area and tier names
  const transformData = (data) => {
    const grouped = data.reduce((acc, item) => {
      const { indicatorId, status, title, name, areaId, tierId } = item;
      const area = allAreas.find((a) => a.id === areaId) || {};
      const tier = area.tier ? area.tier.find((t) => t.id === tierId) : {};
      // console.log(JSON.stringify(area));
      // console.log(JSON.stringify(tier));
      const areaName = area.areaName || "-";
      const tierName = tier.tierName || "-";

      acc[indicatorId] = acc[indicatorId] || {
        indicatorId,
        title,
        areaName,
        tierName,
        users: {},
      };
      acc[indicatorId].users[name] = status;
      return acc;
    }, {});

    return Object.values(grouped).map((item) => ({
      indicatorId: item.indicatorId,
      title: item.title,
      areaName: item.areaName,
      tierName: item.tierName,
      ...item.users,
    }));
  };

  useEffect(() => {
    i18n.changeLanguage(user.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.language]);

  const fetchIndicators = async (pageIndex = 0, accumulatedData = []) => {
    const headersList = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.loginToken,
      "Accept-Language": user.language,
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MTBS_API_URL}indicators?pageIndex=${pageIndex}`,
        {
          headers: headersList,
        }
      );
      const newData = accumulatedData.concat(response.data.indicators);
      if (response.data.pageIndex < response.data.pageCount - 1) {
        return fetchIndicators(pageIndex + 1, newData);
      } else {
        setIndicators(newData);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Token expired");
        Cookies.remove("userData");
        setUser(null);
      } else {
        console.log("Error loading indicators:", error.message);
      }
    }
  };

  useEffect(() => {
    if (user) {
      const headersList = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.loginToken,
        "Accept-Language": user.language,
      };
      fetchIndicators();
      axios
        .get(`${process.env.REACT_APP_MTBS_API_URL}questionnaires/areas`, {
          headers: headersList,
        })
        .then((response) => {
          setAllAreas(response.data);
          // const transformed = transformData(indicators);
          // setTransformedData(transformed);
        })
        .catch((error) => {
          console.log("Error fetching area data:", error.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (indicators.length > 0 && allAreas.length > 0) {
      const transformed = transformData(indicators);
      console.log(transformed)
      setTransformedData(transformed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicators, allAreas]);

  // Dynamically generate columns including area and tier names
  const columns = [
    {
      name: "Indicator ID",
      selector: (row) => row.indicatorId,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      wrap: true,
      grow: 2, // Allows the title column to take more space
    },
    {
      name: "Area",
      selector: (row) => row.areaName,
      sortable: true,
    },
    {
      name: "Tier",
      selector: (row) => row.tierName,
      sortable: true,
    },
    ...Array.from(new Set(indicators.map((item) => item.name))).map((name) => ({
      name: `${name}`,
      selector: (row) => (row[name] >= 0 ? row[name] : t("Not applicable")),
      sortable: true,
      conditionalCellStyles: [
        {
          when: row => row[name] >= 4,
          style: {
            backgroundColor: 'rgba(81, 216, 112, 0.9)'
          },
        },
        {
          when: row => row[name] > 1.5 && row[name] < 4.0,
          style: {
            backgroundColor: 'rgba(243, 217, 39, 0.6)'
          },
        },
        {
          when: row => row[name] >= 0 && row[name] <= 1.5,
          style: {
            backgroundColor: 'rgba(218, 23, 22, 0.5)'
          },
        }]
    })),
  ];

  const export2csv = () => {
    try {
      const opts = {};
      const parser = new Parser(opts);
      const csv = parser.parse(transformedData);
      console.log(csv);
      downloadFile("text/csv", csv, "export.csv");
    } catch (err) {
      console.error(err);
    }
  };

  function downloadFile(mimeType, payload, filename) {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      `data:${mimeType};charset=utf-8,${encodeURIComponent(payload)}`
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  const getTableForArea = (areaName) => {
    return (
        <div className='table-overflow' style={{marginBottom: "10px"}}>
          <h2 className="results">{t(areaName)}</h2>
          <DataTable
              columns={columns}
              data={transformedData.filter(row => row.areaName === areaName)}
              defaultSortFieldId={1}
              highlightOnHover
          />
        </div>
    )
  }

  return (
      <>
        <HeaderForMenu/>
        <div className='wrapper'>
        <aside className='aside question-title'>
          <div className='aside-title'>
            <h1>{t("Admin Report")}</h1>
          </div>
        </aside>
        <article className='aside-choices'>
          <Button onClick={export2csv} className='mb-3'>
            Export to CSV
          </Button>
          {allAreas.map((area) => getTableForArea(area.areaName))}
        </article>
      </div>
    </>
  );
};

export default Report;
