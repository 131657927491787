import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import useUserStore from "../stores/userStore";
import Cookies from "js-cookie";

const PrivateRoute = ({ children }) => {
  const { user, setUser } = useUserStore();
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  // Function to decode JWT token and check if it's expired
  const isTokenValid = (token) => {
    const base64Url = token.split(".")[1]; // Get payload part of the token
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = JSON.parse(window.atob(base64));
    const exp = payload.exp;
    const currentTime = Date.now() / 1000; // Convert to seconds
    return exp > currentTime;
  };

  // Function to check user authentication
  const checkAuthentication = () => {
    const userDataString = Cookies.get("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      if (userData.loginToken !== "" && isTokenValid(userData.loginToken)) {
        console.log(
          "PrivateRoute, checkAuthentication: token found in cookie and is valid",
        );
        if (!user)
          setUser({
            id: userData.id,
            name: userData.name,
            email: userData.email,
            loginToken: userData.loginToken,
          });
        return true;
      } else {
        return false; // Token is not valid
      }
    }
    return false; // User is not authenticated
  };

  useEffect(() => {
    const isAuthenticated = checkAuthentication();
    setIsAuthenticated(isAuthenticated);

    // Effect cleanup function
    return () => {
      // Cleanup logic if needed
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;
