import React, { useState, useEffect } from "react";
import HeaderForMenu from "../HeaderForMenu";
import SurveyCards from "./SurveyCards";
import axios from "axios";
import documentationImage from "../../img/MTBS-Documentation.svg";
import toolsImage from "../../img/MTBS-Tools2.svg";
import caseStudiesImage from "../../img/MTBS-CaseStudies.svg";
import referencesImage from "../../img/MTBS-References.svg";
import useUserStore from "../../stores/userStore";
import Cookies from "js-cookie";
// import { Card } from "react-bootstrap";
import useQuestionnaireStore from "../../stores/questionnaireStore";
// import moment from "moment";
import { useTranslation } from "react-i18next";

const LandingPage = () => {
  const { t } = useTranslation();
  const [areas, setAreas] = useState(null);
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);
  // const availableQuestionnaires = useQuestionnaireStore(state => state.availableQuestionnaires);
  const questionnaireResults = useQuestionnaireStore(
    (state) => state.questionnaireResults
  );
  const [selectedQuestionaireResultId, setSelectedQuestionaireResultId] =
    useState(null);
  const [selectedQuestionaireResult, setSelectedQuestionaireResult] =
    useState(null);

  ////////////////////////////////////////////
  //  load the Questionnaire Areas //////
  ////////////////////////////////////////////
  useEffect(() => {
    if (user && user.loginToken) {
      let headersList = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.loginToken,
        "Accept-Language": user.language,
      };

      axios
        .get(process.env.REACT_APP_MTBS_API_URL + "questionnaires/areas", {
          headers: headersList,
        })
        .then((response) => {
          setAreas(response.data);
        })
        .catch((error) => {
          if (error.status === 401) {
            console.log(
              "Landing Page: Load Areas ERROR 401: Token expired=" +
                user.loginToken
            );
            Cookies.remove("userData");
            setUser("", "", "", "", null);
          } else {
            // Handle other errors
            console.log(
              "Landing Page: Load Areas, error=" +
                JSON.stringify(error.status) +
                "message=" +
                error.message
            );
          }
        });
    } else {
      console.log(
        "Landing Page: Load Areas: -> no user found in store =" +
          JSON.stringify(user)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // // if there is only 1 Questionnaire result, set that id as active
  // useEffect(() => {
  //   if (questionnaireResults && questionnaireResults.length === 1) {
  //     // console.log(" There is 1 questionnaire result found and set to selected");
  //     setSelectedQuestionaireResultId(questionnaireResults[0].id);
  //     setSelectedQuestionaireResult(questionnaireResults[0]);
  //   }
  // }, [questionnaireResults]);

  // Selecteer altijd de questionnaire met de hoogste ID als actief
  useEffect(() => {
    if (questionnaireResults && questionnaireResults.length > 0) {
      // Sorteer de questionnaire results op ID in aflopende volgorde
      const highestIdResult = questionnaireResults.sort(
        (a, b) => b.id - a.id
      )[0];
      setSelectedQuestionaireResultId(highestIdResult.id);
      setSelectedQuestionaireResult(highestIdResult);

      console.log(
        "Highest ID questionnaire result found and set to selected (" +
          highestIdResult.id +
          ")"
      );
    }
  }, [questionnaireResults]);

  // const findSelectedQuestionnairResult = (selectedId) => {
  //   return questionnaireResults.find((a) => a.id === parseInt(selectedId));
  // };

  // const handleSelectChange = (event) => {
  //   setSelectedQuestionaireResultId(event.target.value);
  //   setSelectedQuestionaireResult(
  //     findSelectedQuestionnairResult(event.target.value)
  //   );
  // };

  return (
    <>
      <HeaderForMenu></HeaderForMenu>
      {/* If there are more than 1 result sets (user has filled in a questionnaire multiple times or more than 1 ) 
            If all result sets are status in another status than IN_PROGRESS (so NEW, COMPLETED or REMOVED) let the user choose to 
            create a new ResultSet for a specific Questionnaire
        */}
      {/* <>
        {questionnaireResults && questionnaireResults.length > 1 ? (
          <Card>
            <select
              value={selectedQuestionaireResultId}
              onChange={handleSelectChange}
            >
              <option value=''>Select a Session</option>
              {questionnaireResults.map((result) => (
                <option key={result.id} value={result.id}>
                  Session: {result.id}, Started:{" "}
                  {moment(result.started).format("YYYY-MM-DD HH:MM")}
                </option>
              ))}
            </select>
          </Card>
        ) : null}
      </> */}
      <div className='wrapper mb-7'>
        <article className='aside-choices'>
          <div className='card-container'>
            <div className='parent-card-column'>
              <div className='card-column'>
                <div className='c-cta-card small-card top-row-card'>
                  <h2 className='cta-card__title'>{t("Documentation")}</h2>
                  {areas ? (
                    <div className='circle-image-wrapper-wrapper'>
                      <div className='circle-wrapper'>
                        <img
                          className='landing-page-image'
                          src={documentationImage}
                          alt={"documentation"}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className='cta-card__actions'>
                    <a
                      className='c-button--secondary button--icon cta-card__button '
                      href={"/Documentation/documentation"}
                    >
                      <span className='button__label'>{t("Read More")}</span>
                      <span className='button__icon'>
                        <svg
                          aria-hidden='true'
                          focusable='false'
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15.8052 7.34438C15.9276 7.50863 16 7.71227 16 7.93282C16 8.1082 15.9542 8.27288 15.8739 8.41557C15.8255 8.50399 15.7625 8.58653 15.6851 8.65974L8.35886 15.591C7.95842 15.9699 7.32392 15.955 6.94167 15.5578C6.55942 15.1606 6.57416 14.5315 6.9746 14.1527L12.5066 8.91885L0.987982 8.91885C0.443414 8.91885 0.00195368 8.47739 0.00195372 7.93282C0.00195376 7.38825 0.443414 6.94679 0.987982 6.94679L12.5109 6.94679L6.97418 1.70854C6.57476 1.33065 6.56005 0.703147 6.94133 0.306968C7.32261 -0.0892109 7.95549 -0.104041 8.35491 0.273844L15.6869 7.21062C15.7311 7.25238 15.7705 7.29718 15.8052 7.34438Z'
                            fill='currentcolor'
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='c-cta-card small-card top-row-card'>
                  <h2 className='cta-card__title'>{t("Tools")}</h2>

                  {areas ? (
                    <div className='circle-image-wrapper-wrapper'>
                      <div className='circle-wrapper'>
                        <img
                          className='landing-page-image'
                          src={toolsImage}
                          alt={"tools"}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className='cta-card__actions'>
                    <a
                      className='c-button--secondary button--icon cta-card__button '
                      href={"/Documentation/tools"}
                    >
                      <span className='button__label'>{t("Read More")}</span>
                      <span className='button__icon'>
                        <svg
                          aria-hidden='true'
                          focusable='false'
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15.8052 7.34438C15.9276 7.50863 16 7.71227 16 7.93282C16 8.1082 15.9542 8.27288 15.8739 8.41557C15.8255 8.50399 15.7625 8.58653 15.6851 8.65974L8.35886 15.591C7.95842 15.9699 7.32392 15.955 6.94167 15.5578C6.55942 15.1606 6.57416 14.5315 6.9746 14.1527L12.5066 8.91885L0.987982 8.91885C0.443414 8.91885 0.00195368 8.47739 0.00195372 7.93282C0.00195376 7.38825 0.443414 6.94679 0.987982 6.94679L12.5109 6.94679L6.97418 1.70854C6.57476 1.33065 6.56005 0.703147 6.94133 0.306968C7.32261 -0.0892109 7.95549 -0.104041 8.35491 0.273844L15.6869 7.21062C15.7311 7.25238 15.7705 7.29718 15.8052 7.34438Z'
                            fill='currentcolor'
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className='card-column'>
                <div className='c-cta-card small-card top-row-card'>
                  <h2 className='cta-card__title'>{t("Case Studies")}</h2>
                  {areas ? (
                    <div className='circle-image-wrapper-wrapper'>
                      <div className='circle-wrapper'>
                        <img
                          className='landing-page-image'
                          src={caseStudiesImage}
                          alt={"case_studies"}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className='cta-card__actions'>
                    <a
                      className='c-button--secondary button--icon cta-card__button '
                      href={"/Documentation/case_studies"}
                    >
                      <span className='button__label'>{t("Read More")}</span>
                      <span className='button__icon'>
                        <svg
                          aria-hidden='true'
                          focusable='false'
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15.8052 7.34438C15.9276 7.50863 16 7.71227 16 7.93282C16 8.1082 15.9542 8.27288 15.8739 8.41557C15.8255 8.50399 15.7625 8.58653 15.6851 8.65974L8.35886 15.591C7.95842 15.9699 7.32392 15.955 6.94167 15.5578C6.55942 15.1606 6.57416 14.5315 6.9746 14.1527L12.5066 8.91885L0.987982 8.91885C0.443414 8.91885 0.00195368 8.47739 0.00195372 7.93282C0.00195376 7.38825 0.443414 6.94679 0.987982 6.94679L12.5109 6.94679L6.97418 1.70854C6.57476 1.33065 6.56005 0.703147 6.94133 0.306968C7.32261 -0.0892109 7.95549 -0.104041 8.35491 0.273844L15.6869 7.21062C15.7311 7.25238 15.7705 7.29718 15.8052 7.34438Z'
                            fill='currentcolor'
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='c-cta-card small-card top-row-card'>
                  <h2 className='cta-card__title'>{t("References")}</h2>
                  {areas ? (
                    <div className='circle-image-wrapper-wrapper'>
                      <div className='circle-wrapper'>
                        <img
                          className='landing-page-image'
                          src={referencesImage}
                          alt={"references"}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className='cta-card__actions'>
                    <a
                      className='c-button--secondary button--icon cta-card__button '
                      href={"/Documentation/references"}
                    >
                      <span className='button__label'>{t("Read More")}</span>
                      <span className='button__icon'>
                        <svg
                          aria-hidden='true'
                          focusable='false'
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15.8052 7.34438C15.9276 7.50863 16 7.71227 16 7.93282C16 8.1082 15.9542 8.27288 15.8739 8.41557C15.8255 8.50399 15.7625 8.58653 15.6851 8.65974L8.35886 15.591C7.95842 15.9699 7.32392 15.955 6.94167 15.5578C6.55942 15.1606 6.57416 14.5315 6.9746 14.1527L12.5066 8.91885L0.987982 8.91885C0.443414 8.91885 0.00195368 8.47739 0.00195372 7.93282C0.00195376 7.38825 0.443414 6.94679 0.987982 6.94679L12.5109 6.94679L6.97418 1.70854C6.57476 1.33065 6.56005 0.703147 6.94133 0.306968C7.32261 -0.0892109 7.95549 -0.104041 8.35491 0.273844L15.6869 7.21062C15.7311 7.25238 15.7705 7.29718 15.8052 7.34438Z'
                            fill='currentcolor'
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='parent-card-column'>
              <>
                {selectedQuestionaireResultId &&
                selectedQuestionaireResult &&
                areas ? (
                  <>
                    <SurveyCards
                      areas={areas}
                      questionnaireResult={selectedQuestionaireResult}
                    />
                  </>
                ) : null}
              </>

              {/*  */}
            </div>
          </div>
        </article>
      </div>{" "}
      {/*Wrapper end */}
    </>
  );
};

export default LandingPage;
