import React, { useState, useEffect } from "react";
import HeaderForMenu from "../HeaderForMenu";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";
import useUserStore from "../../stores/userStore";
import airQualityImage from "../../img/MTBS-Area-AirQuality.svg";
import coastalErosionImage from "../../img/MTBS-Area-CoastalErosion.svg";
import wasteManagementImage from "../../img/MTBS-Area-WasteManagement.svg";
import energyEfficiencyImage from "../../img/MTBS-Area-EnergyEfficiency.svg";
import Recommendations from "./Recommendations";
import Indicators from "./Indicators";
import axios from "axios";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const Results = () => {
  const { areaId, resultId } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [area, setArea] = useState(null);
  const [allAreas, setAllAreas] = useState([]);
  const user = useUserStore((state) => state.user);
  const [viewSelection, setViewSelection] = useState("both");

  useEffect(() => {
    i18n.changeLanguage(user.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initialise / load the data
  useEffect(() => {
    console.log("Survey: trying to load data");
    if (user && allAreas.length === 0) {
      let headersList = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.loginToken,
        "Accept-Language": user.language,
      };
      console.log("Survey: starting to load data");
      // get the area and it's tiers
      axios
        .get(process.env.REACT_APP_MTBS_API_URL + "questionnaires/areas", {
          headers: headersList,
        })
        .then((response) => {
          setAllAreas(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log(
              "Survey: load questionnaires areas Error 401: Token invalid=" +
                JSON.stringify(user.loginToken),
            );
            Cookies.remove("userData");
          } else {
            // Handle other errors
            console.log(
              "Results: load questionnaires areas, Error= " +
                error.response.status +
                "; " +
                error.message,
            );
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId]);

  useEffect(() => {
    setArea(allAreas.find((a) => a.id === parseInt(areaId)));
  }, [allAreas, areaId]);

  const handleAreaChange = (selectedAreaId) => {
    navigate(`/Results/${selectedAreaId}/1/${resultId}`);
    setArea(allAreas.find((a) => a.id === parseInt(selectedAreaId)));
  };

  function replaceSpacesAndLowercase(str) {
    return str.replace(/\s+/g, "_").toLowerCase();
  }

  // Object met geïmporteerde afbeeldingen
  const areasImages = {
    air_quality: airQualityImage,
    coastal_erosion: coastalErosionImage,
    waste_management: wasteManagementImage,
    energy_efficiency: energyEfficiencyImage,
  };

  const backToTest = () => {
    navigate(`/Survey/${areaId}/1/${resultId}`);
  };

  const backToHome = () => {
    navigate("/")
  };

  return (
    <>
      <HeaderForMenu></HeaderForMenu>
      <div className="wrapper">
        <aside className="aside question-title">
          <div className="aside-title">
            <h1>{t("Results")}</h1>
          </div>
          <Dropdown>
            <Dropdown.Toggle
              className="area-select-dropdown"
              id="dropdown-basic"
            >
              {area ? t(area.areaName) : "Select Area"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {allAreas.map((area) => (
                <Dropdown.Item
                  key={area.id}
                  onClick={() => handleAreaChange(area.id)}
                >
                  <div className="dropdown-text">{t(area.areaName)}</div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {/* <h1>{area ? area.areaName: null }</h1> */}
          {area ? (
            <div className="circle-image-wrapper-wrapper">
              <div className="circle-wrapper-results">
                <img
                  src={areasImages[replaceSpacesAndLowercase(area.areaName)]}
                  className="landing-page-image"
                  alt={area.areaName}
                />
              </div>
            </div>
          ) : null}
        </aside>
        <article className="aside-choices">
          <div className="view-selection-toggle mb-3">
            <Button
              className={"results-select-button"}
              active={viewSelection === "indicators"}
              onClick={() => setViewSelection("indicators")}
            >
              {t("Indicators")}
            </Button>
            <Button
              className={"results-select-button"}
              active={viewSelection === "recommendations"}
              onClick={() => setViewSelection("recommendations")}
            >
              {t("Recommendations")}
            </Button>
            <Button
              className={"results-select-button"}
              active={viewSelection === "both"}
              onClick={() => setViewSelection("both")}
            >
              {t("Both")}
            </Button>
          </div>

          {/* Conditioneel renderen op basis van viewSelection */}
          {viewSelection === "indicators" && (
            <Indicators areaId={areaId} resultId={resultId} />
          )}
          {viewSelection === "recommendations" && (
            <Recommendations areaId={areaId} resultId={resultId} />
          )}
          {viewSelection === "both" && (
            <>
              <Indicators areaId={areaId} resultId={resultId} />
              <Recommendations areaId={areaId} resultId={resultId} />
            </>
          )}
          <div className="wizard__footer">
            <Button
              className="c-button--primary button--icon"
              type="button"
              onClick={() => backToTest()}
            >
              <span className="button__icon">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0.194719 8.65508C0.0723964 8.49084 9.80496e-08 8.28722 9.53976e-08 8.06669C9.32885e-08 7.8913 0.0457908 7.72661 0.126068 7.58392C0.17456 7.49551 0.237531 7.41297 0.3149 7.33977L7.64114 0.40848C8.04158 0.0296292 8.67608 0.044497 9.05833 0.441688C9.44058 0.838879 9.42584 1.46799 9.0254 1.84684L3.49335 7.08066L15.012 7.08066C15.5566 7.08066 15.998 7.52212 15.998 8.06669C15.998 8.61126 15.5566 9.05272 15.012 9.05272L3.48909 9.05272L9.02583 14.291C9.42524 14.6689 9.43995 15.2964 9.05867 15.6925C8.67739 16.0887 8.04451 16.1036 7.64509 15.7257L0.313053 8.78889C0.268903 8.74712 0.229453 8.7023 0.194719 8.65508Z"></path>
                </svg>
              </span>
              <span className="button__label">{t("To Test")}</span>
            </Button>
            <Button
              className="c-button--primary button--icon"
              type="button"
              onClick={() => backToHome()}
            >
              <span className="button__label">{t("Home")}</span>
              <span className="button__icon">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.8052 7.34438C15.9276 7.50863 16 7.71227 16 7.93282C16 8.1082 15.9542 8.27288 15.8739 8.41557C15.8255 8.50399 15.7625 8.58653 15.6851 8.65974L8.35886 15.591C7.95842 15.9699 7.32392 15.955 6.94167 15.5578C6.55942 15.1606 6.57416 14.5315 6.9746 14.1527L12.5066 8.91885L0.987982 8.91885C0.443414 8.91885 0.00195368 8.47739 0.00195372 7.93282C0.00195376 7.38825 0.443414 6.94679 0.987982 6.94679L12.5109 6.94679L6.97418 1.70854C6.57476 1.33065 6.56005 0.703147 6.94133 0.306968C7.32261 -0.0892109 7.95549 -0.104041 8.35491 0.273844L15.6869 7.21062C15.7311 7.25238 15.7705 7.29718 15.8052 7.34438Z"
                    fill="currentcolor"
                  ></path>
                </svg>
              </span>
            </Button>
          </div>
        </article>
      </div>{" "}
      {/*Wrapper end */}
    </>
  );
};

export default Results;
