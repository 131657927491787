import React, { useState, useEffect } from "react";
import HeaderForMenu from "../HeaderForMenu";
import ProgressBar from "@ramonak/react-progress-bar";
import { Button, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import TierStatusses from "./TierStatusses";
import airQualityImage from "../../img/MTBS-Area-AirQuality.svg";
import coastalErosionImage from "../../img/MTBS-Area-CoastalErosion.svg";
import wasteManagementImage from "../../img/MTBS-Area-WasteManagement.svg";
import energyEfficiencyImage from "../../img/MTBS-Area-EnergyEfficiency.svg";
import useUserStore from "../../stores/userStore";
import axios from "axios";
import Cookies from "js-cookie";
import useQuestionnaireStore from "../../stores/questionnaireStore";
import { useTranslation } from "react-i18next";

const Survey = () => {
  const { t, i18n } = useTranslation();
  const user = useUserStore((state) => state.user);
  const { areaId, tierId, resultId } = useParams();
  const navigate = useNavigate();
  const setUser = useUserStore((state) => state.setUser);
  const availableQuestionnaires = useQuestionnaireStore(
    (state) => state.availableQuestionnaires
  );
  const [area, setArea] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [activeTierId, setActiveTierId] = useState(0);
  const [totalTiers, setTotalTiers] = useState(0);
  const [progressionStep, setProgressionStep] = useState(0); // Start at first tier, 0 is first, 1 second tier etc
  const [progression, setProgression] = useState(0); // Percentage filled in
  const [previousAnswers, setPreviousAnswers] = useState([]); // Answers given earlier and loaded from the API

  useEffect(() => {
    i18n.changeLanguage(user.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function findTierOrderInArea(area, tierId) {
    // function needed to determine the progression step
    const tierIdInt = parseInt(tierId, 10);
    for (let i = 0; i < area.tier.length; i++) {
      if (parseInt(area.tier[i].id, 10) === tierIdInt) {
        return i;
      }
    }
    console.log(
      "Geen tier gevonden met het opgegeven id(" +
        tierId +
        ") in de gespecificeerde area. Eerste tier gekozen"
    );
    return null;
  }

  function findFirstTierIdInArea(area) {
    // function needed to determine the id of the first tier in the Area
    if (area && area.tier) {
      if (area.tier.length === 0) {
        console.log(
          "Survey, findFirstTierIdInArea: error! area = " + JSON.stringify(area)
        );
        return -1;
      }
      return area.tier[0].id;
    }
    console.log("Geen tier gevonden in de area. Error!!");
    return null;
  }

  function replaceSpacesAndLowercase(str) {
    return str.replace(/\s+/g, "_").toLowerCase();
  }

  // Object met geïmporteerde afbeeldingen
  const areasImages = {
    air_quality: airQualityImage,
    coastal_erosion: coastalErosionImage,
    waste_management: wasteManagementImage,
    energy_efficiency: energyEfficiencyImage,
  };

  // Initialise / load the data
  useEffect(() => {
    console.log("Survey: trying to load data");
    if (user && allAreas.length === 0) {
      let headersList = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.loginToken,
        "Accept-Language": user.language,
      };
      console.log("Survey: starting to load data");
      // get the area and it's tiers
      axios
        .get(process.env.REACT_APP_MTBS_API_URL + "questionnaires/areas", {
          headers: headersList,
        })
        .then((response) => {
          console.log(response.data);
          setAllAreas(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log(
              "Survey: load questionnaires areas Error 401: Token invalid=" +
                JSON.stringify(user.loginToken)
            );
            Cookies.remove("userData");
          } else {
            // Handle other errors
            console.log(
              "Survey: load questionnaires areas, Error= " +
                error.response.status +
                "; " +
                error.message
            );
          }
        });
    }

    // Load all the questions
    if (
      user &&
      user.loginToken &&
      activeTierId > 0 &&
      availableQuestionnaires
    ) {
      let headersList = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.loginToken,
        "Accept-Language": user.language,
      };
      axios
        .get(
          process.env.REACT_APP_MTBS_API_URL +
            "questionnaires/" +
            availableQuestionnaires[0].id +
            "/areas/" +
            areaId +
            "/tiers/" +
            activeTierId +
            "/questions",
          {
            headers: headersList,
          }
        )
        .then((response) => {
          console.log(
            "Survey: loading resultid/area/tier" +
              JSON.stringify(resultId) +
              "/" +
              JSON.stringify(areaId) +
              "/" +
              JSON.stringify(activeTierId) +
              " questions for tiersetting all questions in state"
          );
          console.log(response.data);
          setQuestions(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log(
              "Survey: load questionnaires tier questions Error 401: Token invalid=" +
                user.loginToken
            );
            // Cookies.remove('userData');
            setUser("", "", "", "", null);
          } else {
            // Handle other errors
            console.log(
              "Survey:  ERROR loading (/users/+user.id+/questionnaires/RESULTID/areas/+areaId,): " +
                JSON.stringify(areaId) +
                "/" +
                JSON.stringify(activeTierId) +
                "error=" +
                error.message
            );
          }
        });
    } else {
      console.log(
        "Survey: load all questions of active tier: no user&user token found in store"
      );
    }

    // Load all the given answers that were given earlier
    if (user && resultId !== 0 && resultId) {
      let headersList = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.loginToken,
        "Accept-Language": user.language,
      };
      // get the answers of this resultset
      axios
        .get(
          process.env.REACT_APP_MTBS_API_URL +
            "results/" +
            resultId +
            "/areas/" +
            areaId,
          {
            headers: headersList,
          }
        )
        .then((response) => {
          console.log(
            "Survey: Loading previous Answers resultId=" +
              JSON.stringify(resultId) +
              " ,areaId=" +
              JSON.stringify(areaId),
            "=" + JSON.stringify(response.data.resultAnswers)
          );
          setPreviousAnswers(response.data.resultAnswers);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("Token expired");
            Cookies.remove("userData");
            setUser("", "", "", "", null);
          } else {
            // Handle other errors
            console.log(
              "Survey: ERROR loading (/results/+resultId (=" +
                JSON.stringify(resultId) +
                ")+/areas/+areaId(=" +
                JSON.stringify(areaId) +
                ")): ",
              error.message
            );
          }
        });
    } else {
      console.log("Survey: error loading results");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId, activeTierId]);

  useEffect(() => {
    setArea(allAreas.find((a) => a.id === parseInt(areaId)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAreas]);

  useEffect(() => {
    // Set also the amount of tiers in that area. This is used by the progressbar
    if (area) {
      setTotalTiers(area.tier.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  useEffect(() => {
    if (area) {
      // If an tierId is used in the url, then use this as the active tier.
      if (tierId) {
        let tierOrder = findTierOrderInArea(area, tierId);
        if (tierOrder) {
          setActiveTierId(tierId);
          setProgressionStep(tierOrder);
        }
      }
      let firstTierId = findFirstTierIdInArea(area);
      if (firstTierId) {
        setActiveTierId(firstTierId);
        setProgressionStep(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area, tierId]);

  useEffect(() => {
    if (area) {
      // If  setActiveTierId is set from a sub component->update the progression and questions etc
      let tierOrder = findTierOrderInArea(area, activeTierId);
      if (tierOrder !== null) {
        setProgressionStep(tierOrder);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTierId]);

  useEffect(() => {
    // in stap 2, op 50%
    if (totalTiers === 0) {
      setProgression(0);
    } else {
      setProgression(Math.round(100 * (progressionStep / totalTiers)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressionStep, totalTiers]);

  // const handleCompleteAndGoToResults = (toResults) => {
  //   const answersToUpdate = previousAnswers.reduce((acc, answer) => {
  //     acc[answer.questionId] = answer.answerOption.id;
  //     return acc;
  //   }, {});

  //   // Roep updateAnswersInDataBase aan met de geformatteerde antwoorden
  //   updateAnswersInDataBase(resultId, answersToUpdate);
  //   navigate(`/Results/${areaId}/${tierId}/${resultId}`);
  // };

  const handleCompleteAndGoToResults = async () => {
    const answersToUpdate = previousAnswers.reduce((acc, answer) => {
      acc[answer.questionId] = answer.answerOption.id;
      return acc;
    }, {});

    const url = `${process.env.REACT_APP_MTBS_API_URL}results/${resultId}/answers`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.loginToken}`,
    };

    try {
      const response = await axios.post(url, answersToUpdate, { headers });

      if (response.status === 200 || response.status === 202) {
        console.log("Survey: Answers updated successfully");
        navigate(`/Results/${areaId}/${tierId}/${resultId}`);
      } else {
        // Handle any status other than 200 OK
        console.error(
          "Survey: Failed to update answers, status code: ",
          response.status
        );
      }
    } catch (error) {
      console.error("Survey: Error updating answers: ", error);
      // Handle error here, possibly show a message to the user
    }
  };

  const findNextTier = () => {
    if (area && area.tier) {
      let nextTier = area.tier.find(
        (t) => t.ordinal === parseInt(progressionStep + 2)
      );
      if (nextTier) setActiveTierId(nextTier.id);
    }
  };

  const findPreviousTier = () => {
    if (area && area.tier) {
      let previousTier = area.tier.find(
        (t) => t.ordinal === parseInt(progressionStep)
      );
      if (previousTier) setActiveTierId(previousTier.id);
    }
  };

  const handleNext = () => {
    // Move to the next tier of questions
    if (progressionStep !== totalTiers - 1) {
      // Maak een map van de antwoorden
      const answersToUpdate = previousAnswers.reduce((acc, answer) => {
        acc[answer.questionId] = answer.answerOption.id;
        return acc;
      }, {});

      // Roep updateAnswersInDataBase aan met de geformatteerde antwoorden
      updateAnswersInDataBase(resultId, answersToUpdate);

      findNextTier();
      setProgressionStep(progressionStep + 1);
      window.scrollTo(0, 0);
    }
  };

  const handlePrev = () => {
    // Move to the previous tier of questions question
    if (progressionStep > 0) {
      findPreviousTier();
      setProgressionStep(progressionStep - 1);
    }
  };

  const updateAnswersInDataBase = async (resultQuestionnaireId, answers) => {
    // Controleer of het answers object leeg is
    if (Object.keys(answers).length === 0) {
      console.log("Geen antwoorden om op te slaan, overslaan API-aanroep.");
      return; // Vroegtijdig beëindigen van de functie als er geen antwoorden zijn
    }
    const url =
      process.env.REACT_APP_MTBS_API_URL +
      `results/${resultQuestionnaireId}/answers`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.loginToken, // Replace with actual auth token
    };

    try {
      const response = await axios.post(url, answers, { headers });
      console.log("answers:" + JSON.stringify(answers));
      console.log("Survey: updateAnswersInDataBase Response:", response.data);
      // Handle response here
    } catch (error) {
      console.error(
        "Survey: updateAnswersInDataBase, Error updating answers:",
        JSON.stringify(error)
      );
      // Handle error here
    }
  };

  const handleAnswer = (questionId, answerOptionId) => {
    // console.log('-------handleAnswer is called');
    // console.log(`Question ID: ${questionId}, AnswerOptionId: ${answerOptionId}`);
    // Controleer of er al een antwoord bestaat voor de vraag
    const existingAnswerIndex = previousAnswers.findIndex(
      (answer) => answer.questionId === questionId
    );

    let updatedPreviousAnswers;
    if (existingAnswerIndex !== -1) {
      // Als er al een antwoord bestaat, update dit
      updatedPreviousAnswers = previousAnswers.map((answer, index) => {
        if (index === existingAnswerIndex) {
          return {
            ...answer,
            answerOption: {
              ...answer.answerOption,
              id: answerOptionId,
              // Update hier eventueel andere velden
            },
          };
        }
        return answer;
      });
    } else {
      // Als er geen antwoord bestaat, voeg een nieuw toe
      const newAnswer = {
        // Vul hier de structuur van het nieuwe antwoord in, bijvoorbeeld:
        questionId: questionId,
        answerOption: {
          id: answerOptionId,
          // Vul hier de rest van de structuur in
        },
      };
      updatedPreviousAnswers = [...previousAnswers, newAnswer];
    }

    // Update de state met de nieuwe lijst van antwoorden
    setPreviousAnswers(updatedPreviousAnswers);
  };

  const renderQuestions = (question) => {
    // Zoek het vorige antwoord voor de huidige vraag
    const previousAnswer = previousAnswers.find(
      (a) => a.questionId === question.id
    );

    return (
      <div>
        <p className='question-description'>{question.question}</p>
        <ToggleButtonGroup
          type='radio'
          className='w-100'
          name={"buttonOptions" + question.id}
        >
          {question.answerOptions.map((answerOption, index) => (
            <ToggleButton
              key={index}
              id={`question-${question.id}-answer-${answerOption.id}`}
              name={`question-${question.id}`}
              variant='outline-secondary'
              className='survey-button'
              onChange={() => handleAnswer(question.id, answerOption.id)}
              // Controleer of dit antwoord het eerder geselecteerde antwoord is
              active={
                previousAnswer &&
                previousAnswer.answerOption.id === answerOption.id
              }
            >
              {/* {JSON.stringify(previousAnswer && previousAnswer.answerOption.id === answerOption.id)} */}
              {answerOption.answer}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <div className='horizontal-line'></div>
      </div>
    );
  };
  return (
    <>
      <HeaderForMenu></HeaderForMenu>
      {/* <h3>{JSON.stringify(progression +" - step="+progressionStep)}</h3> */}
      <ProgressBar
        completed={progression}
        trailColor='transparent'
        bgColor='#51D870' // Fill color of the progress bar
      />
      <div className='wrapper'>
        <aside className='aside question-title'>
          <div className='aside-title'>
            <h1>{area ? t(area.areaName) : null}</h1>
          </div>
          {area ? (
            <div className='circle-image-wrapper-wrapper'>
              <div className='circle-wrapper'>
                <img
                  src={areasImages[replaceSpacesAndLowercase(area.areaName)]}
                  className='landing-page-image'
                  alt={area.areaName}
                />
              </div>
            </div>
          ) : null}
          {/* <p>{JSON.stringify(area)}</p> */}
          <TierStatusses
            tiers={area ? area.tier : null}
            activeTierId={activeTierId}
            jumpToTierId={setActiveTierId}
          />
        </aside>
        <article className='aside-choices'>
          {questions.map((question, index) => renderQuestions(question))}

          <div className='wizard__footer'>
            {!(progressionStep === 0) ? (
              <Button
                className='c-button--primary button--icon wizard__prev-button '
                type='button'
                js-hook-wizard-backlink=''
                onClick={() => handlePrev()}
              >
                <span className='button__icon__survey'>
                  <svg
                    aria-hidden='true'
                    focusable='false'
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M0.194719 8.65508C0.0723964 8.49084 9.80496e-08 8.28722 9.53976e-08 8.06669C9.32885e-08 7.8913 0.0457908 7.72661 0.126068 7.58392C0.17456 7.49551 0.237531 7.41297 0.3149 7.33977L7.64114 0.40848C8.04158 0.0296292 8.67608 0.044497 9.05833 0.441688C9.44058 0.838879 9.42584 1.46799 9.0254 1.84684L3.49335 7.08066L15.012 7.08066C15.5566 7.08066 15.998 7.52212 15.998 8.06669C15.998 8.61126 15.5566 9.05272 15.012 9.05272L3.48909 9.05272L9.02583 14.291C9.42524 14.6689 9.43995 15.2964 9.05867 15.6925C8.67739 16.0887 8.04451 16.1036 7.64509 15.7257L0.313053 8.78889C0.268903 8.74712 0.229453 8.7023 0.194719 8.65508Z'></path>
                  </svg>
                  <span className='button__label__survey'> {t("Back")}</span>
                </span>
              </Button>
            ) : (
              <></>
            )}
            {!(progressionStep === totalTiers - 1) ? (
              <Button
                className='c-button--primary button--icon'
                type='button'
                onClick={() => handleNext()}
              >
                <span className='button__label__survey'>{t("Next")} </span>
                <span className='button__icon__survey'>
                  <svg
                    aria-hidden='true'
                    focusable='false'
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M15.8052 7.34438C15.9276 7.50863 16 7.71227 16 7.93282C16 8.1082 15.9542 8.27288 15.8739 8.41557C15.8255 8.50399 15.7625 8.58653 15.6851 8.65974L8.35886 15.591C7.95842 15.9699 7.32392 15.955 6.94167 15.5578C6.55942 15.1606 6.57416 14.5315 6.9746 14.1527L12.5066 8.91885L0.987982 8.91885C0.443414 8.91885 0.00195368 8.47739 0.00195372 7.93282C0.00195376 7.38825 0.443414 6.94679 0.987982 6.94679L12.5109 6.94679L6.97418 1.70854C6.57476 1.33065 6.56005 0.703147 6.94133 0.306968C7.32261 -0.0892109 7.95549 -0.104041 8.35491 0.273844L15.6869 7.21062C15.7311 7.25238 15.7705 7.29718 15.8052 7.34438Z'></path>
                  </svg>
                </span>
              </Button>
            ) : (
              <>
                <Button
                  className='c-button--primary button--icon'
                  type='button'
                  onClick={() => handleCompleteAndGoToResults()}
                >
                  <span className='button__label__survey'>{t("Finish")} </span>
                  <span className='button__icon__survey'>
                    <svg
                      aria-hidden='true'
                      focusable='false'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M15.8052 7.34438C15.9276 7.50863 16 7.71227 16 7.93282C16 8.1082 15.9542 8.27288 15.8739 8.41557C15.8255 8.50399 15.7625 8.58653 15.6851 8.65974L8.35886 15.591C7.95842 15.9699 7.32392 15.955 6.94167 15.5578C6.55942 15.1606 6.57416 14.5315 6.9746 14.1527L12.5066 8.91885L0.987982 8.91885C0.443414 8.91885 0.00195368 8.47739 0.00195372 7.93282C0.00195376 7.38825 0.443414 6.94679 0.987982 6.94679L12.5109 6.94679L6.97418 1.70854C6.57476 1.33065 6.56005 0.703147 6.94133 0.306968C7.32261 -0.0892109 7.95549 -0.104041 8.35491 0.273844L15.6869 7.21062C15.7311 7.25238 15.7705 7.29718 15.8052 7.34438Z'></path>
                    </svg>
                  </span>
                </Button>
              </>
            )}
          </div>
        </article>
      </div>{" "}
      {/*Wrapper end */}
    </>
  );
};

export default Survey;
