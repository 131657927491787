import React from "react";
import airQualityImage from "../../img/MTBS-Area-AirQuality.svg";
import coastalErosionImage from "../../img/MTBS-Area-CoastalErosion.svg";
import wasteManagementImage from "../../img/MTBS-Area-WasteManagement.svg";
import energyEfficiencyImage from "../../img/MTBS-Area-EnergyEfficiency.svg";
import { useTranslation } from "react-i18next";

// Object met geïmporteerde afbeeldingen
const areasImages = {
  air_quality: airQualityImage,
  coastal_erosion: coastalErosionImage,
  waste_management: wasteManagementImage,
  energy_efficiency: energyEfficiencyImage,
};
const SurveyCards = ({
  areas,
  questionnaireResult,
  showResultsButtons = true,
}) => {
  const { t } = useTranslation();
  const buildAreaUrl = (areas, index) => {
    let base = "Survey"; // Default to 'Survey' for the base URL
    return areas && areas.length > index
      ? `/${base}/${areas[index].id}/${areas[index].tier?.[0]?.id || ""}/${questionnaireResult.id}`
      : "";
  };

  const replaceSpacesAndLowercase = (str) => {
    return str.replace(/\s+/g, "_").toLowerCase();
  };

  const renderActionButtons = (area, globalIndex) => {
    const surveyUrl = buildAreaUrl(areas, globalIndex);
    const resultsUrl = surveyUrl.replace("/Survey/", "/Results/");
    const showDoTestButton = true;
    const showResultsButton = true;
    return (
      <>
        {showDoTestButton && (
          <a
            className="c-button--secondary button--icon cta-card__button"
            href={surveyUrl}
          >
            <span className="button__label">{t("Do Test")}</span>
            <span className="button__icon">
              <svg
                aria-hidden="true"
                focusable="false"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8052 7.34438C15.9276 7.50863 16 7.71227 16 7.93282C16 8.1082 15.9542 8.27288 15.8739 8.41557C15.8255 8.50399 15.7625 8.58653 15.6851 8.65974L8.35886 15.591C7.95842 15.9699 7.32392 15.955 6.94167 15.5578C6.55942 15.1606 6.57416 14.5315 6.9746 14.1527L12.5066 8.91885L0.987982 8.91885C0.443414 8.91885 0.00195368 8.47739 0.00195372 7.93282C0.00195376 7.38825 0.443414 6.94679 0.987982 6.94679L12.5109 6.94679L6.97418 1.70854C6.57476 1.33065 6.56005 0.703147 6.94133 0.306968C7.32261 -0.0892109 7.95549 -0.104041 8.35491 0.273844L15.6869 7.21062C15.7311 7.25238 15.7705 7.29718 15.8052 7.34438Z"
                  fill="currentcolor"
                ></path>
              </svg>
            </span>
          </a>
        )}
        {showResultsButton && (
          <a
            className="c-button--secondary button--icon cta-card__button"
            href={resultsUrl}
          >
            <span className="button__label">{t("Results")}</span>
            <span className="button__icon">
              <svg
                aria-hidden="true"
                focusable="false"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8052 7.34438C15.9276 7.50863 16 7.71227 16 7.93282C16 8.1082 15.9542 8.27288 15.8739 8.41557C15.8255 8.50399 15.7625 8.58653 15.6851 8.65974L8.35886 15.591C7.95842 15.9699 7.32392 15.955 6.94167 15.5578C6.55942 15.1606 6.57416 14.5315 6.9746 14.1527L12.5066 8.91885L0.987982 8.91885C0.443414 8.91885 0.00195368 8.47739 0.00195372 7.93282C0.00195376 7.38825 0.443414 6.94679 0.987982 6.94679L12.5109 6.94679L6.97418 1.70854C6.57476 1.33065 6.56005 0.703147 6.94133 0.306968C7.32261 -0.0892109 7.95549 -0.104041 8.35491 0.273844L15.6869 7.21062C15.7311 7.25238 15.7705 7.29718 15.8052 7.34438Z"
                  fill="currentcolor"
                ></path>
              </svg>
            </span>
          </a>
        )}
      </>
    );
  };

  // Groepeer de kaarten in rijen van twee
  const groupedAreas = [];
  for (let i = 0; i < areas.length; i += 2) {
    groupedAreas.push(areas.slice(i, i + 2));
  }

  return (
    <>
      {groupedAreas.map((group, groupIndex) => (
        <div key={groupIndex} className="card-column">
          {group.map((area, index) => (
            <div
              key={index}
              className="c-cta-card small-card top-row-card cta-card--green"
            >
              <h2 className="cta-card__title">{t(area.areaName)}</h2>
              <div className="circle-image-wrapper-wrapper">
                <div className="circle-wrapper">
                  <img
                    className="landing-page-image"
                    src={areasImages[replaceSpacesAndLowercase(area.areaName)]}
                    alt={area.areaName}
                  />
                </div>
              </div>
              <div className="cta-card__actions">
                {/* Pas hier de index aan om de globale index te gebruiken */}
                {renderActionButtons(area, groupIndex * 2 + index)}
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default SurveyCards;
