import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  "en-US": {
    translation: {
      Documentation: "Documentation",
      "Case Studies": "Case Studies",
      "Case studies": "Case Studies",
      Tools: "Tools",
      References: "References",
      "Waste Management": "Waste Management",
      "Coastal Erosion": "Coastal Erosion",
      "Energy Efficiency": "Energy Efficiency",
      "Air Quality": "Air Quality",
      "Read More": "Read More",
      "Do Test": "Do Test",
      Results: "Results",
      Next: "Next",
      Previous: "Previous",
      Back: "Back",
      Finish: "Finish",
      Recommendations: "Recommendations",
      Tier: "Tier",
      Tiers: "Tiers",
      Priorities: "Priorities",
      Policies: "Policies",
      Situation: "Situation",
      Infrastructure: "Infrastructure",
      "Quick win": "Quick win",
      "Short term": "Short term",
      "Medium term": "Medium term",
      "High impact": "High impact",
      "Medium impact": "Medium impact",
      "Low impact": "Low impact",
      "Unknown impact": "Unknown impact",
      "Show All": "Show All",
      Both: "Both",
      "To Test": "To Test",
      Welcome: "Welcome",
      Home: "Home",
      "New Questionnaire": "New Questionnaire",
      "Results Overview (admin)": "Results Overview (admin)",
      "Admin Results Report": "Admin Results Report",
      Logout: "Logout",
      Area: "Area",
      Menu: "Menu",
      Language: "Language",
      English: "English",
      Portuguese: "Portuguese",
      French: "French",
      Reason: "Reason",
      Indicator: "Indicator",
      Indicators: "Indicators",
      "Implementation Time": "Implementation Time",
      Impact: "Impact",
      Description: "Description",
      Benefits: "Benefits",
      Disclaimer: "Disclaimer",
      "Not applicable": "N/A",
      DisclaimerText:
        "This webtool persists user provided data to a centralized storage container in order to function. The provider of this webtool has made considerable efforts to secure access to the stored data, however residual risks always remain. By using this webtool the user accepts these risks and agrees to the storage of the provided data.",
    },
  },
  "pt-PT": {
    translation: {
      Documentation: "Documentação (Ingles)",
      "Case Studies": "Estudos de Caso (Ingles)",
      "Case studies": "Estudos de Caso (Ingles)",
      Tools: "Ferramentas (Ingles)",
      References: "Referências (Ingles)",
      "Waste Management": "Gestão de Resíduos",
      "Coastal Erosion": "Erosão Costeira",
      "Energy Efficiency": "Eficiência Energética",
      "Air Quality": "Qualidade do Ar",
      "Read More": "Leia Mais",
      "Do Test": "Fazer Teste",
      Results: "Resultados",
      Next: "Próximo",
      Previous: "Anterior",
      Back: "Voltar",
      Finish: "Terminar",
      Recommendations: "Recomendações",
      Tier: "Nível",
      Tiers: "Níveis",
      Priorities: "Prioridades",
      Policies: "Políticas",
      Situation: "Situação",
      Infrastructure: "Infraestrutura",
      "Quick win": "Vitória rápida",
      "Short term": "Curto prazo",
      "Medium term": "Médio prazo",
      "High impact": "Alto impacto",
      "Medium impact": "Médio impacto",
      "Low impact": "Baixo impacto",
      "Unknown impact": "Impacto desconhecido",
      "Show All": "Mostrar Tudo",
      Both: "Ambos",
      "To Test": "Ir Testar",
      Welcome: "Bem-vindo",
      Home: "Início",
      "New Questionnaire": "Novo Questionário",
      "Results Overview (admin)": "Visão Geral dos Resultados (admin)",
      "Admin Results Report": "Visão Geral dos Resultados (admin)",
      Logout: "Sair",
      Area: "Área",
      Menu: "Menu",
      Language: "Língua",
      English: "Inglês",
      Portuguese: "Português",
      French: "Francês",
      Reason: "Razão",
      Indicator: "Indicador",
      Indicators: "Indicadores",
      "Implementation Time": "Tempo de Implementação",
      Impact: "Impacto",
      Description: "Descrição",
      Benefits: "Benefícios",
      Disclaimer: "Aviso Legal",
      "Not applicable": "n.a.",
      DisclaimerText:
        "Esta webtool persiste os dados fornecidos pelo usuário em um contêiner de armazenamento centralizado para funcionar. O fornecedor desta ferramenta web fez esforços consideráveis para proteger o acesso aos dados armazenados, no entanto, permanecem sempre riscos residuais. Ao utilizar esta webtool o usuário aceita estes riscos e concorda com o armazenamento dos dados fornecidos.",
    },
  },
  "fr-FR": {
    translation: {
      Documentation: "Documentation (Anglais)",
      "Case Studies": "Études de cas (Anglais)",
      "Case studies": "Études de cas (Anglais)",
      Tools: "Outils (Anglais)",
      References: "Les références (Anglais)",
      "Waste Management": "Gestion des déchets",
      "Coastal Erosion": "L'érosion côtière",
      "Energy Efficiency": "Efficacité énergétique",
      "Air Quality": "Qualité de l'air",
      "Read More": "En savoir plus",
      "Do Test": "Faire un test",
      Results: "Résultats",
      Next: "Suivant",
      Previous: "Précédent",
      Back: "Dos",
      Finish: "Finition",
      Recommendations: "Recommandations",
      Tier: "Étage",
      Tiers: "Niveaux",
      Priorities: "Priorités",
      Policies: "Stratégies",
      Situation: "Situation",
      Infrastructure: "Infrastructure",
      "Quick win": "Reússite rapide",
      "Short term": "Court terme",
      "Medium term": "Moyen terme",
      "High impact": "Fort impact",
      "Medium impact": "Impact moyen",
      "Low impact": "Faible impact",
      "Unknown impact": "Impact inconnu",
      "Show All": "Afficher tout",
      Both: "Les deux",
      "To Test": "Tester",
      Welcome: "Accueillir",
      Home: "Accueil",
      "New Questionnaire": "Nouveau Questionnaire",
      "Results Overview (admin)": "Aperçu des Résultats (admin)",
      "Admin Results Report": "Aperçu des Résultats (admin)",
      Logout: "Se déconnecter",
      Area: "Zone",
      Menu: "Menu",
      Language: "Langue",
      English: "Anglais",
      French: "Français",
      Portuguese: "Portugais",
      Reason: "Raison",
      Indicator: "Indicateur",
      Indicators: "Indicateurs",
      "Implementation Time": "Temps de mise en œuvre",
      Impact: "Impact",
      Description: "Description",
      Benefits: "Avantages",
      Disclaimer: "Clause de non-responsabilité",
      "Not applicable": "S.O.",
      DisclaimerText:
        "Cet outil Web conserve les données fournies par l'utilisateur dans un conteneur de stockage centralisé afin de fonctionner. Le fournisseur de cet outil Web a déployé des efforts considérables pour sécuriser l'accès aux données stockées, mais des risques résiduels subsistent toujours. En utilisant cet outil Web, l'utilisateur accepte ces risques et accepte le stockage des données fournies.",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
