import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Hamburger from "hamburger-react";
import useUserStore from "../stores/userStore";
import axios from "axios";
import HeaderLogo from "../assets/svg/brand/logo-for-header.png";
import { Form, Offcanvas, Container, Row, Col, Button } from "react-bootstrap";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import useQuestionnaireStore from "../stores/questionnaireStore";
import { useTranslation } from "react-i18next";

const HeaderForMenu = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const availableQuestionnaires = useQuestionnaireStore(
    (state) => state.availableQuestionnaires
  );
  const [defaultShow, setDefaultShow] = useState(false);
  // const setUser = useUserStore(state => state.setUser)
  const createNewQuestionnaireResult = useCallback((questionnaireId) => {
    // console.log('create new questionnaire result with token= '+user.loginToken);

    let headersList = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.loginToken,
    };
    axios
      .post(
        process.env.REACT_APP_MTBS_API_URL +
          "results/users/" +
          user.id +
          "/questionnaires/" +
          availableQuestionnaires[0].id +
          "/create",
        {},
        { headers: headersList }
      )
      .then((response) => {
        // console.log('Login: new questionnaire created')
        // console.log(JSON.stringify(response.data))
        //Reload the questionnairs so we can add it to the state store
        // loadQuestionnairesResults(questionnaireId);
      })
      .catch((error) => {
        // Handle error here
        console.error(
          "Menu: Error creating Questionnaire Result",
          JSON.stringify(error)
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUserLocale = async (newLocale) => {
    try {
      const response = await axios.put(
        process.env.REACT_APP_MTBS_API_URL + `/users/${user.id}`,
        {
          ...user,
          preferredLocale: newLocale,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.loginToken}`,
          },
        }
      );

      if (response.status === 200) {
        // Update de gebruiker in de Zustand store met de nieuwe locale
        // setUser(user.id, user.name, user.email, newLocale, user.loginToken);
        // Optioneel: update de taal in i18n
      }
    } catch (error) {
      console.error("Error updating user locale", error);
    }
  };

  const setUserLanguage = useUserStore((state) => state.setLanguage);
  const currentLanguage = useUserStore((state) => state.user.language);

  const handleLanguageChange = (languageCode) => {
    i18n.changeLanguage(languageCode); // Wijzigt de taal van i18n
    setUserLanguage(languageCode); // Update de taal in de Zustand store
    updateUserLocale(languageCode)
      .then(() => {
        // window.location.reload(); // Ververs het huidige scherm
        navigate("/");
      })
      .catch((error) => {
        console.error("Failed to update user locale: ", error);
        // Optioneel: toon een foutmelding aan de gebruiker
      });
  };

  return (
    <>
      <header className='header header-menu'>
        <Container fluid>
          <Row className='align-items-center justify-content-between'>
            <Col className='header__logo' xs='auto'>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/");
                }}
              >
                <img
                  className='logo-image ms-7 me-3 mb-1 mt-1'
                  src={HeaderLogo}
                  alt='headerlogo'
                />
              </a>
            </Col>
            <Col className='header__label'>
              <h3 className='d-inline-block ms-3'>PMAWCA Sustainable Ports</h3>
            </Col>
            <Col xs='auto'>
              <span
                className='ms-3 me-3'
                style={{
                  fontWeight: "500",
                  fontSize: "clamp(10px, 1vw, 18px)",
                }}
              >
                {t("Welcome")} {user.name}
              </span>
            </Col>
            <Col xs='auto'>
              <section className='scroll-section' id='default'>
                <Hamburger
                  className='header__toggle me-3'
                  size={24}
                  toggle={() => setDefaultShow(true)}
                />
                <Offcanvas
                  show={defaultShow}
                  onHide={() => setDefaultShow(false)}
                  placement='end'
                >
                  <Offcanvas.Header>
                    <Offcanvas.Title>{t("Menu")}</Offcanvas.Title>
                    <Button
                      className='btn-close-menu'
                      onClick={() => setDefaultShow(false)}
                    >
                      {"\u2715"}
                    </Button>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <div className='px-4 py-3'>
                      <Row className='mb-3 ms-0 me-0'>
                        <ul className='list-unstyled'>
                          <a
                            href='#/'
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/");
                            }}
                          >
                            <CsLineIcons
                              icon='home'
                              className='me-2'
                              size='17'
                            />{" "}
                            <span className='align-middle'>{t("Home")}</span>
                          </a>
                        </ul>
                      </Row>
                      <Row className='mb-3 ms-0 me-0'>
                        <ul className='list-unstyled'>
                          <a
                            href='#/'
                            onClick={(e) => {
                              e.preventDefault();
                              createNewQuestionnaireResult();
                              navigate("/login");
                            }}
                          >
                            <CsLineIcons
                              icon='plus'
                              className='me-2'
                              size='17'
                            />{" "}
                            <span className='align-middle'>
                              {t("New Questionnaire")}
                            </span>
                          </a>
                        </ul>
                      </Row>
                      {user.isAdmin ? (
                        <Row className='mb-3 ms-0 me-0'>
                          <ul className='list-unstyled'>
                            <a
                              href='#/'
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/report");
                              }}
                            >
                              <CsLineIcons
                                icon='archive'
                                className='me-2'
                                size='17'
                              />{" "}
                              <span className='align-middle'>
                                {t("Results Overview (admin)")}
                              </span>
                            </a>
                          </ul>
                        </Row>
                      ) : (
                        <></>
                      )}
                      <Row>
                        <div className='language-toggle'>
                          <Form.Label>{t("Language")}</Form.Label>
                          <Form.Check
                            type='radio'
                            label={t("English")}
                            id='stackedRadio1'
                            name='stackedRadio'
                            onChange={() => handleLanguageChange("en-US")}
                            checked={currentLanguage === "en-US"}
                          />
                          <Form.Check
                            type='radio'
                            label={t("French")}
                            id='stackedRadio2'
                            name='stackedRadio'
                            onChange={() => handleLanguageChange("fr-FR")}
                            checked={currentLanguage === "fr-FR"}
                          />
                          <Form.Check
                            type='radio'
                            label={t("Portuguese")}
                            id='stackedRadio2'
                            name='stackedRadio'
                            onChange={() => handleLanguageChange("pt-PT")}
                            checked={currentLanguage === "pt-PT"}
                          />
                        </div>
                      </Row>
                      <Row className='mb-1 ms-0 me-0'>
                        <ul className='list-unstyled'>
                          <li>
                            <a
                              href='#/'
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/login");
                              }}
                            >
                              <CsLineIcons
                                icon='logout'
                                className='me-2'
                                size='17'
                              />{" "}
                              <span className='align-middle'>
                                {t("Logout")}
                              </span>
                            </a>
                          </li>
                        </ul>
                      </Row>
                      <Row>
                        <div className='menu-disclaimer'>
                          <b>{t("Disclaimer")}:</b>
                          <br />
                          {t("DisclaimerText")}
                        </div>
                      </Row>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
              </section>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};

export default HeaderForMenu;
