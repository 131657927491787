import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./components/authorisation/Login";
import Survey from "./components/wizard/Survey";
import LandingPage from "./components/LandingPage/LandingPage";
import { Wizard } from "react-use-wizard";
import PrivateRoute from "./privateRoute/privateRoute";
import Results from "./components/Results/Results";
import DataViewer from "./components/documentation/DataViewer";
import Report from "./components/report/Report";

function App() {
  return (
    <Wizard startIndex={0}>
      <Routes>
        <Route path='/login' element={<Login />} />

        <Route
          path='/'
          element={
            <PrivateRoute>
              <LandingPage />
            </PrivateRoute>
          }
        />
        <Route
          path='/Results/:areaId/:tierId/:resultId'
          element={
            <PrivateRoute>
              <Results />
            </PrivateRoute>
          }
        />

        <Route
          path='/Survey/:areaId/'
          element={
            <PrivateRoute>
              <Survey />
            </PrivateRoute>
          }
        />
        <Route
          path='/Survey/:areaId/:tierId/:resultId'
          element={
            <PrivateRoute>
              <Survey />
            </PrivateRoute>
          }
        />
        <Route
          path='/Documentation/:subject'
          element={
            <PrivateRoute>
              <DataViewer />
            </PrivateRoute>
          }
        />
        <Route
          path='/Report/'
          element={
            <PrivateRoute>
              <Report />
            </PrivateRoute>
          }
        />
      </Routes>
    </Wizard>
  );
}

export default App;
